import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {Capacitor} from '@capacitor/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {AuthenticationProviderService} from './services/authentication-provider-service-impl.service';
import {App, AppLaunchUrl} from '@capacitor/app';
import {SplashScreen} from '@capacitor/splash-screen';
import {AppInsightsService} from './services/app-insights/app-insights.service';
import {FormService} from "./services/form.service";
import {WorkspaceConfigService} from './services/workspace-config.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
    private appLaunchUrl = new Subject<AppLaunchUrl>();

    attachmentsMenuOpen$ = new BehaviorSubject<boolean>(false);

    constructor(
        private ngZone: NgZone,
        private router: Router,
        @Inject('AuthenticationProviderService')
        private authenticationProviderService: AuthenticationProviderService,
        private formService: FormService,
        public workspaceConfigService: WorkspaceConfigService,
        appInsightService: AppInsightsService,
    ) {
        try {
            appInsightService.initialize();
        } catch (ex) {
            console.error('Unable to load application insights', ex);
        }

        this.initializeApp();
    }

    attachmentsOpened() {
        this.attachmentsMenuOpen$.next(true);
    }

    attachmentsClosed() {
        this.attachmentsMenuOpen$.next(false);
    }

    async ngOnInit() {
        await this.formService.initialize();
        await this.authenticationProviderService.initialize();
    }

    initializeApp() {
        this.appLaunchUrl.subscribe(launch => {
            this.ngZone.run(() => {
                try {
                    if (environment.production === false) {
                        localStorage.setItem('LINK', JSON.stringify(launch));
                    }
                } catch (ex) {
                    console.error('unable to save received launch', launch);
                }

                // iOS URL types have the form nl.recognize.assetcheck://authenticate?code=bla
                // The url parser has problems parsing it. The dummy url is a workaround for this problem
                let url;
                const launchUrlTypeMatch = `${launch.url}`.match(/^[^:]+(:\/\/)([^/]+)$/);
                if (launchUrlTypeMatch) {
                    const rewrittenLaunchUrl = `https://dummy.url.justforparsing/${launchUrlTypeMatch[2]}`;
                    url = new URL(rewrittenLaunchUrl);
                } else {
                    url = new URL(launch.url);
                }

                const {pathname, search} = url;

                this.router.navigateByUrl(pathname + search);
            });
        });

        if (Capacitor.isNativePlatform()) {
            SplashScreen.hide();
        }

        App.getLaunchUrl().then(it => {
            if (it?.url) {
                this.appLaunchUrl.next(it);
            }
        });
        App.addListener('appUrlOpen', it => this.appLaunchUrl.next(it));
    }
}
